// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown          as fasFaAngleDown          } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft          as fasFaAngleLeft          } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight         as fasFaAngleRight         } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faArrowLeft          as fasFaArrowLeft          } from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import { faArrowRight         as fasFaArrowRight         } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faBars               as fasFaBars               } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCalendarAlt        as fasFaCalendarAlt        } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import { faEnvelope           as fasFaEnvelope           } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faHeart              as fasFaHeart              } from "@fortawesome/pro-solid-svg-icons/faHeart";
import { faPhone              as fasFaPhone              } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faSearch             as fasFaSearch             } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTimes              as fasFaTimes              } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faUsers              as fasFaUsers              } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { faHandshake          as fasFaHandshake          } from "@fortawesome/pro-solid-svg-icons/faHandshake";
import { faPersonWalking      as fasFaPersonWalking      } from "@fortawesome/pro-solid-svg-icons/faPersonWalking";
import { faLocationDot        as fasFaLocationDot        } from "@fortawesome/pro-solid-svg-icons/faLocationDot";

import { faArrowPointer       as farFaArrowPointer       } from "@fortawesome/pro-regular-svg-icons/faArrowPointer";
import { faBook               as farFaBook               } from "@fortawesome/pro-regular-svg-icons/faBook";
import { faCalendar           as farFaCalendar           } from "@fortawesome/pro-regular-svg-icons/faCalendar";
import { faCalendarAlt        as farFaCalendarAlt        } from "@fortawesome/pro-regular-svg-icons/faCalendarAlt";
import { faClipboardListCheck as farFaClipboardListCheck } from "@fortawesome/pro-regular-svg-icons/faClipboardListCheck";
import { faClock              as farFaClock              } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faComment            as farFaComment            } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faEnvelope           as farFaEnvelope           } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faFolder             as farFaFolder             } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faMapMarkedAlt       as farFaMapMarkedAlt       } from "@fortawesome/pro-regular-svg-icons/faMapMarkedAlt";
import { faMapMarker          as farFaMapMarker          } from "@fortawesome/pro-regular-svg-icons/faMapMarker";
import { faQuestionCircle     as farFaQuestionCircle     } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faTag                as farFaTag                } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faUserCircle         as farFaUserCircle         } from "@fortawesome/pro-regular-svg-icons/faUserCircle";
import { faUsers              as farFaUsers              } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faGuitars            as farFaGuitars            } from "@fortawesome/pro-regular-svg-icons/faGuitars";
import { faPartyHorn          as farFaPartyHorn          } from "@fortawesome/pro-regular-svg-icons/faPartyHorn";
import { faFerrisWheel        as farFaFerrisWheel        } from "@fortawesome/pro-regular-svg-icons/faFerrisWheel";
import { faMegaphone          as farFaMegaphone          } from "@fortawesome/pro-regular-svg-icons/faMegaphone";

import { faFacebook           as fabFaFacebook           } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faFlickr             as fabFaFlickr             } from "@fortawesome/free-brands-svg-icons/faFlickr";
import { faInstagram          as fabFaInstagram          } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faYoutube            as fabFaYoutube            } from "@fortawesome/free-brands-svg-icons/faYoutube";

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaAngleLeft, fasFaAngleRight, fasFaArrowLeft, fasFaArrowRight, fasFaBars, fasFaEnvelope, fasFaHeart, fasFaPhone, fasFaSearch, fasFaTimes, fasFaUsers, fasFaCalendarAlt, fasFaHandshake, fasFaPersonWalking, fasFaLocationDot);

/**
 * Add regular icons
 */
library.add(farFaArrowPointer, farFaBook, farFaCalendar, farFaCalendarAlt, farFaClipboardListCheck, farFaClock, farFaComment, farFaEnvelope, farFaFolder, farFaMapMarkedAlt, farFaMapMarker, farFaQuestionCircle, farFaTag, farFaUserCircle, farFaUsers, farFaGuitars, farFaPartyHorn, farFaFerrisWheel, farFaMegaphone);

/**
 * Add brand icons
 */
library.add(fabFaFacebook, fabFaFlickr, fabFaInstagram, fabFaYoutube);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
